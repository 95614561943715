import { Box, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Parallax } from 'react-parallax'
import { useRouter } from 'next/router'

import useTheme from '@hooks/useTheme'
import useGlossary from '@hooks/useGlossary'
import GreenButton from '@components/GreenButton'
import LoadingSkeleton from '@components/LoadingSkeleton'
import { useEffect, useState } from 'react'
import WaitlistModal from '@features/Waitlist/WaitlistModal'

const Root = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  overflow: 'hidden',
  width: '100%',
  margin: `${theme.spacing(5)} 0`,
  color: theme.palette.primary.contrastText,
  [theme.breakpoints.down('md')]: {
    margin: `${theme.spacing(5)} ${theme.spacing(2)}`,
    width: `calc(100% - ${theme.spacing(2)} - ${theme.spacing(2)})`,
  },
}))

const CustomParallax = styled(props => <Parallax {...props} />)(({ theme }) => ({
  '& .react-parallax-content': {
    width: '100%',
    height: '100%',
  },
}))

const ParallaxContent = styled(props => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  padding: theme.spacing(3),
  gap: theme.spacing(2),
  backgroundColor: theme.palette.gray[70],
}))

const MainLabel = styled(props => <Box {...props} />)(({ theme }) => ({
  font: theme.font.rubik.title.medium,
  fontWeight: '600',
  '& .green': {
    color: theme.palette.primary.main,
  },
}))

const Text = styled(props => <Box {...props} />)(({ theme }) => ({
  font: theme.font.roboto.paragraph.large,
  fontWeight: '400',
  maxWidth: '80%',
}))

const Mexico2026Banner = props => {
  const { glossary } = useGlossary()
  const { theme } = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))

  const [waitListModal, setWaitListModal] = useState(false)

  const handleParallaxClick = () => {
    if (!isMd) setWaitListModal(true)
  }


  return (
    <>
      {' '}
      <Root onClick={handleParallaxClick}>
        <CustomParallax
          bgImage={'/images/Banners/register.jpg'}
          bgImageStyle={{ bottom: '-380%' }}
          style={{ height: '100%', width: '100%' }}
          strength={-500}
        >
          <ParallaxContent>
            <MainLabel>{glossary('mexico2026') ?? <LoadingSkeleton width={'300px'} />}</MainLabel>
            <Text>{isMd ? glossary('DescWorldStep15') : glossary('FormText2')}</Text>
            {isMd && (
              <GreenButton sx={{ alignSelf: 'flex-end' }} onClick={() => setWaitListModal(true)}>
                {glossary('JoinNow')}
              </GreenButton>
            )}
          </ParallaxContent>
        </CustomParallax>
      </Root>
      <WaitlistModal
        eventDefId={'676b07e8fe7dd3a44fb49085'}
        eventDefName={'Copa del mundo 2026'}
        open={waitListModal}
        setOpen={setWaitListModal}
        show={false}
        showNoTicketsCopy={false}
      />
    </>
  )
}

export default Mexico2026Banner

import { forwardRef, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useContextLocation } from '@context/Location'
import EventCategoriesSliders from './EventCategoriesSliders'
import EventSliders from './EventSliders'
import PerformersContainer from './Performers/PerformersContainer'
import CategoriesContainer from './Categories/CategoriesContainer'
import {
  RegisterProperty as RegisterPropertyBanner,
  SeasonalRent as SeasonalRentBanner
} from './Banner'
import Mexico2026Banner from './Banner/Mexico2026Banner'

const MainContainer = styled(Box)(({ theme }) => ({
  width: 'clamp(100vw, 100%, 1600px)',
  padding: `${theme.spacing(8)} ${theme.spacing(5)}`,
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(0)}`,
    paddingBottom: theme.spacing(10)
  },
}))

const Main = forwardRef((props, ref) => {

  const { location } = useContextLocation()

  const [selectedLocation, setSelectedLocation] = useState(location)

  useEffect(() => {
    setSelectedLocation(prev => {
      if(prev && !location) return prev
      else return location
    })
  }, [location])

  return <MainContainer ref={ref}>
    <CategoriesContainer/>
    <PerformersContainer/>
    <EventSliders location={selectedLocation}/>
    <Mexico2026Banner/>
    <EventCategoriesSliders location={selectedLocation}/>
    {/* <SeasonalRentBanner/> */}
    <RegisterPropertyBanner/>
  </MainContainer>
})

export default Main

import Head from 'next/head'
import baseConfig from '@baseConfig'
import useGlossarySSR from '@hooks/useGlossarySSR'
import HomeClient from '@clientPages/HomeClient'

const HomeView = ({ locale }) => {
  const { glossarySSR } = useGlossarySSR(locale)

  return (
    <>
      <Head>
        <title>{glossarySSR('MetaTitleHome')}</title>
        <meta name='description' content={glossarySSR('MetaDescriptionHome')} />
        <meta property='og:title' content={glossarySSR('MetaTitleHome')} />
        <meta property='og:description' content={glossarySSR('MetaDescriptionHome')} />
        <meta property='og:type' content='website' />
        <meta property='og:url' content={`${baseConfig.rootDomain}`} />
        <meta property='og:image' content={baseConfig.defaultMetadataImageUrl} />
      </Head>
      <HomeClient />
    </>
  )
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      locale,
    },
  }
}

export default HomeView

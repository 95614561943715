'use client'
import { useRef } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Box, useMediaQuery } from '@mui/material'

import Footer from '@features/Layout/Footer'
import Nav from '@features/Layout/Nav'
import BottomMenu from '@features/Layout/BottomMenu'
import Hero from '@features/Home/Hero'
import Main from '@features/Home/Main'

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: theme.palette.gray[200],
  overflowX: 'hidden',
}))

const HeroContainer = styled(Box)(({ theme }) => ({
  width: '100%',
}))

const HomeClient = props => {

  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))
  const sectionRef = useRef(null)
  const mainSectionRef = useRef(null)

  const scrollToMainSection = () => {
    mainSectionRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return <Root>
    <Nav fromHome changeRef={sectionRef}/>
    <HeroContainer ref={sectionRef}>
      {
        isMd && <Hero onScroll={scrollToMainSection}/>
      }
    </HeroContainer>
    <Main ref={mainSectionRef}/>
    {
      isMd ? <Footer/> : <BottomMenu/>
    }
  </Root>
}

export default HomeClient


import { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import useEvent from '@hooks/useEvent'
import useEventCategory from '@hooks/useEventCategory'
import { getRegionId, getCountryId } from '@helpers/location'
import EventsSlider from '@features/Events/EventsSlider'

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
}))

const EventCategoriesSliders = ({ location }) => {

  const { getEvents, loading } = useEvent()
  const { getEventCategories } = useEventCategory()

  const [events, setEvents] = useState([])
  const [error, setError] = useState(false)
  const [initialFetchDone, setIntitialFetchDone] = useState(false)

  const soccerId = '63d1915bcbda1599579456da'

  const getPlain = arr => {
    let result = []
    arr.forEach( ob => {
      result.push(ob)
      if (ob.subcategories?.length > 0)
        result = result.concat(getPlain(ob.subcategories))
    })
    return result
  }

  const fetchSingleCategoryRegional = useCallback(async category => {
    const region = getRegionId(location)
    if(!region) return await fetchSingleCategoryCountry(category)
    await getEvents({
      num: 20,
      ord: ['date'],
      filter: {
        onlyUpcoming: true,
        category: [category.id],
        region: [region],
        onlyRegion: true,
      },
    })
      .then(resp => {
        if(resp.totalCount === 0) return fetchSingleCategoryCountry(category)
        setEvents(prevEvents => {
          return resp.totalCount > 0
            ? [
              ...prevEvents,
              {
                category,
                data: resp.edges.map(i => i.node),
              },
            ]
            : prevEvents
        })
      })
  }, [events, location])

  const fetchSingleCategoryCountry = useCallback(async category => {
    const country = getCountryId(location)
    await getEvents({
      num: 20,
      ord: ['date'],
      filter: {
        onlyUpcoming: true,
        category: [category.id],
        country: country ? [country] : [],
        onlyCountry: true,
      },
    })
      .then(resp => {
        if(resp.totalCount === 0) return fetchSingleCategoryInternational(category)
        setEvents(prevEvents => {
          return resp.totalCount > 0
            ? [
              ...prevEvents,
              {
                category,
                data: resp.edges.map(i => i.node),
              },
            ]
            : prevEvents
        })
      })
  }, [events, location])

  const fetchSingleCategoryInternational = useCallback(async category => {
    await getEvents({
      num: 20,
      ord: ['date'],
      filter: {
        onlyUpcoming: true,
        category: [category.id],
      },
    })
      .then(resp => {
        setEvents(prevEvents => {
          return resp.totalCount > 0
            ? [
              ...prevEvents,
              {
                category,
                data: resp.edges.map(i => i.node),
              },
            ]
            : prevEvents
        })
      })
  }, [events])

  const fetchCategoriesData = useCallback(async () => {
    try {
      const resp = await getEventCategories()
      const data = getPlain(resp)
      const soccerCat = data.find(cat => cat.id === soccerId)
      if (location) await fetchSingleCategoryRegional(soccerCat)
      else await fetchSingleCategoryInternational(soccerCat)

      for (const x in data) {
        const category = data[x]
        if (category.showInHome && category.id !== soccerId) {
          if (location) await fetchSingleCategoryRegional(category)
          else await fetchSingleCategoryInternational(category)
        }
      }
    } catch(e) {
      setError(true)
    }
    setIntitialFetchDone(true)
  }, [location])

  useEffect(() => {
    if(location===undefined) return
    setEvents([])
    setError(false)
    fetchCategoriesData()
  }, [location])

  return <Root>
    {
      !error && events.map(category => <EventsSlider loading={loading}
        key={category.category.id}
        label={category.category.name}
        data={category.data}
      />)
    }
    {
      !initialFetchDone && <EventsSlider loading={true} key={100} label={''} data={[]}/>
    }
  </Root>
}

export default EventCategoriesSliders

import { useCallback } from 'react'
import { useRouter } from 'next/navigation'

import baseConfig from '@baseConfig'
import MediaMicroCard from '@components/Cards/MediaMicroCard'

const PerformerCard = ({ performer, ...props }) => {

  const router = useRouter()

  const handleClick = useCallback(e => {
    router.push({
      pathname: `/event/search/performer/${performer.id_ticketero ?? performer.id}`,
      query: { name: performer.name },
    })
  }, [router])

  return <MediaMicroCard name={performer.name} onClick={handleClick}
    imageUrl={`${baseConfig.cdnRoot}/${performer.image?.url}`}/>
}

export default PerformerCard

import { useCallback, useEffect, useState, Fragment, useRef } from 'react'
import { Box, Autocomplete, TextField, Button, Paper, Divider, useMediaQuery } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { Popper } from '@mui/base'
import { useRouter } from 'next/navigation'

import useEvent from '@hooks/useEvent'
import useCity from '@hooks/useCity'
import usePerformer from '@hooks/usePerformer'
import useDebounce from '@hooks/useDebounce'
import useError from '@hooks/useError'
import useGlossary from '@hooks/useGlossary'
import useSearch from '@hooks/useSearch'
import useDate from '@hooks/useDate'

import { stringToURL } from '@helpers/urlFormatter'
import CalendarIcon from '@icons/CalendarIcon'
import CityIcon from '@icons/CityIcon'
import PerformerIcon from '@icons/PerformerIcon'
import SearchIcon from '@icons/SearchIcon'
import TrendingTopicIcon from '@icons/TrendingTopicIcon'
import VenueIcon from '@icons/VenueIcon'
import LoadingSkeleton from '@components/LoadingSkeleton'
import LocationPicker from '@features/Filters/Location/LocationPicker'
import { searchEvent, searchSelectionEvent } from '@helpers/GA4Events'

// #region

const Root = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  borderRadius: theme.spacing(2),
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  // overflow: 'hidden',
  zIndex: '5',
}))

const IconContainer = styled(Box)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: theme.spacing(2),
  '& svg path': {
    fill: theme.palette.gray[500],
  },
}))

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiAutocomplete-endAdornment': {
    display: 'none',
  },
  '& .MuiInputBase-root': {
    padding: `${theme.spacing(1)} !important`,
    '&:before, &:after': {
      display: 'none',
    },
  },
  '& input': {
    width: '100%',
  },
  '& fieldset': {
    border: 'none',
  },
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& fieldset': {
    border: 'none',
  },
  [theme.breakpoints.down('md')]: {
    zIndex: '9',
    width: `calc(100% - ${theme.spacing(5)})`,
    height: '38px',
    alignItems: 'center',
  },
}))

const OptionsMobile = styled(props => <Box {...props} />)(({ theme }) => ({
  zIndex: '4',
  width: '100vw',
  height: `calc(100vh - ${theme.spacing(12)})`,
  position: 'fixed',
  top: theme.spacing(12),
  overflowY: 'scroll',
  backgroundColor: theme.palette.gray[200],
  paddingTop: theme.spacing(5),
  '&::-webkit-scrollbar': {
    width: '0px',
    height: '0px',
  },
}))

const StyledButton = styled(props => <Button {...props} />)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.primary.main,
  font: theme.font.roboto.paragraph.medium,
  fontWeight: 600,
  color: theme.palette.primary.contrastText,
  letterSpacing: '3px',
  padding: `0 ${theme.spacing(3)}`,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  borderTopRightRadius: theme.spacing(2),
  borderBottomRightRadius: theme.spacing(2),
  textTransform: 'capitalize',
  width: 'fit-content',
  '&:hover': {
    backgroundColor: theme.palette.primary[600],
  },
}))

const StyledPaper = styled(props => <Paper {...props} />)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.font.black,
  minWidth: '400px',
  '& *::-webkit-scrollbar': {
    width: theme.spacing(1),
    backgroundColor: 'transparent',
  },
  '& *::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: theme.palette.gray[300],
  },
  '& .MuiAutocomplete-listbox': {
    padding: '0 !important',
  },
}))

const StyledPopper = styled(props => <Popper {...props} placement='bottom' />)(({ theme }) => ({
  color: theme.palette.font.black,
  marginTop: theme.spacing(1),
}))

const StyledOption = styled(props => <Box {...props} />)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: theme.spacing(1),
  padding: `0 ${theme.spacing(2)} !important`,
  height: `${theme.spacing(5)} !important`,
  cursor: 'pointer',
  color: theme.palette.gray.main,
  '&.event': {
    height: `${theme.spacing(6)} !important`,
  },
  '&:hover': {
    backgroundColor: theme.palette.primary[100],
  },
}))

const IconOptionContainer = styled(props => <Box {...props} />)(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '&.event': {
    height: theme.spacing(5),
  },
}))

const CalendarBox = styled(props => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: theme.spacing(5),
  height: theme.spacing(5),
  '& svg': {
    position: 'relative',
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  '& .day': {
    position: 'absolute',
    transform: 'translateY(6px)',
    width: theme.spacing(5),
    height: theme.spacing(5),
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: '600',
    color: theme.palette.primary.contrastText,
    lineHeight: '0.8',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& .month': {
      fontSize: '10px',
    },
  },
}))

const NameContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  maxWidth: `calc(100% - ${theme.spacing(8)})`,
}))

const MainLabel = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
}))

const SecondaryLabel = styled(Box)(({ theme }) => ({
  color: theme.palette.gray[500],
  font: theme.font.roboto.paragraph.footNote,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
}))

const ItemGroup = styled('li')(({ theme }) => ({}))

const GroupHeader = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    position: 'sticky',
    top: '0',
    backgroundColor: theme.palette.gray[100],
    zIndex: '1',
  },
}))

const GroupName = styled('div')(({ theme }) => ({
  fontWeight: 600,
}))

const GroupMore = styled('div')(({ theme }) => ({
  fontWeight: 100,
  color: theme.palette.secondary.main,
  cursor: 'pointer',
  font: theme.font.roboto.paragraph.minimum,
}))

const SubGroup = styled('div')(({ theme }) => ({}))

const CustomDivider = styled(props => <Divider {...props} />)(({ theme }) => ({
  margin: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
  background: theme.palette.gray[100],
}))

const LocationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingRight: theme.spacing(2),
}))

const SeeAllResultsOption = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingRight: theme.spacing(2),
  fontStyle: 'italic',
}))

const SearchBarContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxHeight: '280px',
  position: 'absolute',
  zIndex: 999,
  left: 0,
  backgroundColor: '#fff',
  borderRadius: '5px',
  overflowX: 'hidden',
  overflowY: 'scroll',

  '&::-webkit-scrollbar': {
    width: theme.spacing(1),
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: theme.palette.gray[300],
  },
}))

// #endregion

const RESULTS_PER_CATEGORY = 5

const OptionRender = ({ option, ...props }) => {
  const { format: dayFormat } = useDate()

  return (
    <StyledOption
      key={option.id}
      onPointerDown={props.onClick}
      {...props}
      className={`${props?.className} ${option.data_type}`}
    >
      <IconOptionContainer className={option.data_type}>
        {option.data_type === 'trending' && <TrendingTopicIcon />}
        {option.data_type === 'event' && (
          <CalendarBox>
            <CalendarIcon />
            <Box className={'day'}>
              <span>{dayFormat(option.date, 'D', null, option.forum?.region?.timeZone)}</span>
              <span className={'month'}>{dayFormat(option.date, 'MMM', null, option.forum?.region?.timeZone)}</span>
            </Box>
          </CalendarBox>
        )}
        {option.data_type === 'performer' && <PerformerIcon />}
        {option.data_type === 'venue' && <VenueIcon />}
        {option.data_type === 'city' && <CityIcon />}
      </IconOptionContainer>
      <NameContainer>
        <MainLabel>{option.name}</MainLabel>
        {option.data_type === 'event' && (
          <SecondaryLabel>
            {option.time === 'TBA'
              ? `${option.time} - ${option.forum?.name}`
              : `${dayFormat(option.date, 'HH:mm', null, option.forum?.region?.timeZone)} - ${option.forum?.name}`}
          </SecondaryLabel>
        )}
      </NameContainer>
    </StyledOption>
  )
}

const SearchField = props => {
  const { glossary } = useGlossary()
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))
  const router = useRouter()
  const { showAlert } = useError()

  const { getEvents, getSuggestEvents, loading: loadingEvents } = useEvent()
  const { getTicketeroSuggest, loading } = useSearch()
  const { getCities, loading: loadingCities } = useCity()
  const { getPerformers } = usePerformer()
  const textFieldRef = useRef(null)
  const searchBarRef = useRef(null)

  const [options, setOptions] = useState([])
  const [mobileOptions, setMobileOptions] = useState({})
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query, 500)
  const [showMobileOptions, setShowMobileOptions] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(false)

  const setFetchedOptions = useCallback(
    fetchedOptions => {
      const reducedOptions = fetchedOptions.reduce((result, currentItem) => {
        const dataType = currentItem.data_type
        if (!result[dataType]) result[dataType] = []
        result[dataType].push(currentItem)
        return result
      }, {})
      if (!isMd) {
        setMobileOptions(reducedOptions)
      } else {
        setOptions(reducedOptions)
      }
    },
    [isMd]
  )

  const fetchCities = useCallback(
    lastOptions => {
      if (query.length === 0) return setFetchedOptions(lastOptions)

      getCities({
        num: RESULTS_PER_CATEGORY,
        ord: ['name_es'],
        asc: [false],
        pag: 0,
        filter: {
          name: query,
        },
      })
        .then(resp => {
          const _options = resp.edges.map(item => {
            return {
              ...item.node,
              name: item.node?.name ?? item.node?.name_es ?? '',
              data_type: 'city',
            }
          })
          setFetchedOptions([...lastOptions, ..._options])
        })
        .catch(err => setFetchedOptions(lastOptions))
    },
    [query, options, debouncedQuery, mobileOptions, setFetchedOptions]
  )

  const fetchEvents = useCallback(
    (lastOptions, nextOptions = []) => {
      if (query.length === 0) return setFetchedOptions([...lastOptions, ...nextOptions])

      getSuggestEvents({
        query,
        filter: { outdated: false, onlyUpcoming: true },
      })
        .then(resp => {
          const _options = resp.slice(0, RESULTS_PER_CATEGORY).map(item => {
            return {
              ...item,
              data_type: 'event',
            }
          })
          fetchCities([...lastOptions, ..._options, ...nextOptions])
        })
        .catch(err => fetchCities([...lastOptions, ...nextOptions]))
    },
    [query, options, debouncedQuery, mobileOptions]
  )

  const fetchPerformersAndVenues = useCallback(
    lastOptions => {
      if (query.length === 0) {
        getPerformers({
          num: RESULTS_PER_CATEGORY,
          filter: {
            highlighted: true,
          },
        })
          .then(resp => {
            const _options = resp.edges.map(e => ({
              id: `${e.node.id_ticketero}`,
              name: e.node.name,
              data_type: 'performer',
            }))
            fetchEvents([...lastOptions, ..._options])
          })
          .catch(err => fetchEvents(lastOptions))
      } else {
        getTicketeroSuggest({
          query,
        })
          .then(resp => {
            const _optionsPerformer = resp
              .filter(option => option.type_search === 'performer')
              .slice(0, RESULTS_PER_CATEGORY)
              .map(performer => ({
                ...performer,
                data_type: 'performer',
              }))
            const _optionsVenue = resp
              .filter(option => option.type_search === 'venue')
              .slice(0, RESULTS_PER_CATEGORY)
              .map(venue => ({
                ...venue,
                data_type: 'venue',
              }))
            if (resp.length > 0)
              if (resp[0].type_search === 'performer') {
                fetchEvents([...lastOptions, ..._optionsPerformer], _optionsVenue)
              } else {
                fetchEvents([...lastOptions, ..._optionsVenue], _optionsPerformer)
              }
            else fetchEvents(lastOptions)
          })
          .catch(err => fetchEvents(lastOptions))
      }
    },
    [query, options, debouncedQuery, mobileOptions]
  )

  const fetchTrending = useCallback(() => {
    if (query !== '') fetchPerformersAndVenues([])
    else
      getEvents({
        num: RESULTS_PER_CATEGORY,
        ord: ['date'],
        asc: [true],
        pag: 0,
        filter: {
          onlyHighlighted: true,
          onlyUpcoming: true,
          outdated: false,
        },
      })
        .then(resp => {
          const _options = resp.edges.map(item => {
            return {
              ...item.node,
              data_type: 'trending',
            }
          })
          fetchPerformersAndVenues(_options)
        })
        .catch(err => fetchPerformersAndVenues([]))
  }, [query, options, debouncedQuery, mobileOptions])

  const handleSeeMore = useCallback(
    (e, group) => {
      let _query = `query=${debouncedQuery}`
      let _pathname = '/event/search'
      switch (group) {
        case 'trending':
          break
        case 'event':
          break
        case 'performer':
          _pathname = '/event/search/performer'
          break
        case 'venue':
          break
        case 'city':
          break
      }
      if (!isMd) {
        setQuery('')
        setShowMobileOptions(false)
      }
      router.push({
        pathname: _pathname,
        query: _query,
      })
    },
    [router, debouncedQuery]
  )

  const handleOptionClick = useCallback(
    (e, option) => {
      let routerPayload = { pathname: '/event/search' }
      switch (option.data_type) {
        case 'performer':
          routerPayload = {
            pathname: `/event/search/performer/${option.id}`,
            query: { name: option.name },
          }
          searchSelectionEvent(debouncedQuery, 'performer', option.name)
          break
        case 'venue':
          routerPayload = {
            pathname: `/event/search/forum/${option.id}`,
            query: { name: option.name },
          }
          searchSelectionEvent(debouncedQuery, 'venue', option.name)
          break
        case 'trending':
          routerPayload = {
            pathname: `/event/box/explorer/${stringToURL(option.name)}-${stringToURL(option.forum.name)}/${option.id}`,
          }
          searchSelectionEvent(debouncedQuery, 'trending', option.name)
          break
        case 'event':
          routerPayload = {
            pathname: `/event/box/explorer/${stringToURL(option.name)}-${stringToURL(option.forum.name)}/${option.id}`,
          }
          searchSelectionEvent(debouncedQuery, 'event', option.name)
          break
        case 'city':
          routerPayload = { pathname: `/event/search/city/${option.id}` }
          searchSelectionEvent(debouncedQuery, 'city', option.name)
          break
      }
      if (!isMd) {
        setQuery('')
        setShowMobileOptions(false)
      }
      router.push(routerPayload)
    },
    [router, debouncedQuery]
  )

  const handleSearchQuery = (e, newQuery) => {
    searchEvent(newQuery ?? debouncedQuery)
    router.push({
      pathname: '/event/search',
      query: `query=${newQuery ?? debouncedQuery}`,
    })
  }

  useEffect(() => {
    setOptions([])
    setMobileOptions([])
    fetchTrending()
  }, [debouncedQuery])

  useEffect(() => {
    if (query !== '' && showMobileOptions) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [showMobileOptions, query])

  return (
    <Fragment>
      <Root>
        <IconContainer>
          <SearchIcon />
        </IconContainer>
        {isMd ? (
          <>
            <Box ref={searchBarRef} sx={{ position: 'relative', width: '100%' }}>
              <StyledTextField
                ref={textFieldRef}
                size={'small'}
                variant='outlined'
                placeholder={glossary('MainLabelSearch')}
                fullWidth={true}
                value={query}
                onChange={e => setQuery(e.target.value)}
                onFocus={() => setShowSuggestions(true)}
                onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
              />
              {showSuggestions && (
                <SearchBarContainer
                  sx={{
                    top: textFieldRef.current?.clientHeight + 10,
                  }}
                >
                  {loading || loadingCities || loadingEvents
                    ? [0, 1].map(i => (
                        <Box key={i}>
                          <GroupHeader>
                            <GroupName>
                              <LoadingSkeleton width='200px' />
                            </GroupName>
                            <GroupMore>
                              <LoadingSkeleton />
                            </GroupMore>
                          </GroupHeader>
                          <SubGroup>
                            {[0, 1, 2].map(j => (
                              <StyledOption key={j}>
                                <IconOptionContainer>
                                  <LoadingSkeleton width='30px' color='primary' />
                                </IconOptionContainer>
                                <NameContainer>
                                  <LoadingSkeleton width='70vw' />
                                </NameContainer>
                              </StyledOption>
                            ))}
                          </SubGroup>
                        </Box>
                      ))
                    : Object.keys(options).map((type, index) => {
                        if (options[type].length > 0)
                          return (
                            <Box key={index}>
                              <GroupHeader>
                                <GroupName>
                                  {type === 'trending' && glossary('TrendingTopics')}
                                  {type === 'event' && glossary('Events')}
                                  {type === 'performer' && glossary('PerformersLarge')}
                                  {type === 'venue' && glossary('Venues')}
                                  {type === 'city' && glossary('Cities')}
                                </GroupName>
                                {type !== 'venue' && type !== 'city' && (
                                  <GroupMore onClick={e => handleSeeMore(e, type)}>{glossary('SeeAll')}</GroupMore>
                                )}
                              </GroupHeader>
                              <SubGroup>
                                {options[type].map(option => (
                                  <OptionRender
                                    key={type}
                                    option={option}
                                    className={type}
                                    onClick={e => handleOptionClick(e, option)}
                                  />
                                ))}
                                {type !== 'city' && <CustomDivider variant={'middle'} light />}
                              </SubGroup>
                            </Box>
                          )
                      })}
                </SearchBarContainer>
              )}
            </Box>
          </>
        ) : (
          <StyledTextField
            {...props}
            size={'small'}
            variant='outlined'
            placeholder={glossary('MainLabelSearch')}
            value={query}
            onChange={e => setQuery(e.target.value)}
            onFocus={() => setShowMobileOptions(true)}
            onBlur={() =>
              setTimeout(() => {
                setShowMobileOptions(false)
              }, 200)
            }
          />
        )}
        {isMd && (
          <StyledButton color={'primary'} variant={'filled'} fullWidth={true} onClick={handleSearchQuery}>
            {glossary('Search')}
          </StyledButton>
        )}
        {!isMd && (
          <LocationContainer>
            <LocationPicker useLocationContext={true} />
          </LocationContainer>
        )}
      </Root>
      {!isMd && query !== '' && showMobileOptions && (
        <OptionsMobile>
          {!(loading || loadingCities || loadingEvents) &&
            Object.keys(mobileOptions).map((type, index) => {
              if (mobileOptions[type].length > 0 && type !== 'trending')
                return (
                  <Box key={index}>
                    <GroupHeader>
                      <GroupName>
                        {type === 'trending' && glossary('TrendingTopics')}
                        {type === 'event' && glossary('Events')}
                        {type === 'performer' && glossary('PerformersLarge')}
                        {type === 'venue' && glossary('Venues')}
                        {type === 'city' && glossary('Cities')}
                      </GroupName>
                      {type !== 'venue' && type !== 'city' && (
                        <GroupMore onClick={e => handleSeeMore(e, type)}>{glossary('SeeAll')}</GroupMore>
                      )}
                    </GroupHeader>
                    <SubGroup>
                      {mobileOptions[type].map(option => (
                        <OptionRender
                          key={type}
                          option={option}
                          className={type}
                          onClick={e => handleOptionClick(e, option)}
                        />
                      ))}
                      {type !== 'city' && <CustomDivider variant={'middle'} light />}
                    </SubGroup>
                  </Box>
                )
            })}
          {
            //On loading
            (loading || loadingCities || loadingEvents) &&
              [0, 1].map(i => (
                <Box key={i}>
                  <GroupHeader>
                    <GroupName>
                      <LoadingSkeleton width='200px' />
                    </GroupName>
                    <GroupMore>
                      <LoadingSkeleton />
                    </GroupMore>
                  </GroupHeader>
                  <SubGroup>
                    {[0, 1, 2].map(j => (
                      <StyledOption key={j}>
                        <IconOptionContainer>
                          <LoadingSkeleton width='30px' color='primary' />
                        </IconOptionContainer>
                        <NameContainer>
                          <LoadingSkeleton width='70vw' />
                        </NameContainer>
                      </StyledOption>
                    ))}
                  </SubGroup>
                </Box>
              ))
          }
          {
            <SeeAllResultsOption onClick={handleSearchQuery}>
              {`${glossary('ResultsFor')} "${query}"...`}
            </SeeAllResultsOption>
          }
        </OptionsMobile>
      )}
    </Fragment>
  )
}

export default SearchField

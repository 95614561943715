import RegisterProperty from './RegisterProperty'
import SeasonalRent from './SeasonalRent'

export default {
  RegisterProperty,
  SeasonalRent,
}

export {
  RegisterProperty,
  SeasonalRent,
}


import { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import usePerformer from '@hooks/usePerformer'
import useGlossary from '@hooks/useGlossary'
import HomeLabel from '@components/HomeLabel'
import PerformerCard from './PerformerCard'

const Root = styled(Box)(({ theme }) => ({
}))

const CardsContainer = styled(Box)(({ theme }) => ({
  display: 'flex', 
  gap: theme.spacing(2), 
  padding: theme.spacing(2),
  overflowX: 'auto',
  scrollbarWidth: 'thin', 
  whiteSpace: 'nowrap', 
  '& > *': {
    flex: '0 0 auto', 
  },
  '-ms-overflow-style': 'none', 
  'scrollbar-width': 'none', 
  '&::-webkit-scrollbar': {
    display: 'none', 
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
}))

const PerformersContainer = ({ showTitle=true, ...props }) => {

  const { glossary } = useGlossary()
  const { getPerformers } = usePerformer()

  const [performers, setPerformers] = useState([])
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  const fetchPerformers = useCallback(() => {
    getPerformers({
      filter: {
        highlighted: true,
      },
    }).then(resp => setPerformers(resp.edges.map(performer => performer.node)))
      .catch(e => setError(true))
      .finally(() => setLoading(false))
  }, [performers, error, loading])

  useEffect(() => {
    fetchPerformers()
  }, [])

  return !error && performers.length > 0
    ? <Root>
      { showTitle ?
        <HomeLabel>
          { glossary('TopPerformers') }
        </HomeLabel>
        : ''
      }
      <CardsContainer>
        {
          performers.map(performer => <PerformerCard key={performer} performer={performer}/>)
        }
      </CardsContainer>
    </Root>
    :<Box/>
}

export default PerformersContainer

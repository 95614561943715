import { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import useEvent from '@hooks/useEvent'
import useGlossary from '@hooks/useGlossary'
import { getRegionId, getCountryId } from '@helpers/location'
import EventsSlider from '@features/Events/EventsSlider'

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
}))

const EventSliders = ({ location }) => {

  const { glossary } = useGlossary()
  const { getEvents, loading } = useEvent()

  const [events, setEvents] = useState({})
  const [errors, setErrors] = useState({})

  const fetchInternational = useCallback(() => {
    // Eventos internacionales
    const country = getCountryId(location)
    getEvents({
      num: 20,
      ord: ['date'],
      filter: {
        only32Upcoming: true,
        dateChronological: true,
        excludeCountry: country ? [country] : [],
      },
    }).then(resp => {
      setEvents(prevEvents => {
        return {
          ...prevEvents,
          international: resp.edges.map(i => i.node),
        }
      })
    }).catch(err => {
      setErrors(prevErrors => {
        return {
          ...prevErrors,
          international: true,
        }
      })
    })
  }, [events, errors, location])

  const fetchNearRegional = useCallback(() => {
    // Eventos cerca de ti (región)
    const region = getRegionId(location)
    if(!region) return fetchNearCountry()
    getEvents({
      num: 20,
      ord: ['date'],
      filter: {
        omitHighlighted: true,
        onlyUpcoming: true,
        dateChronological: true,
        region: [region],
        onlyRegion: true,
      },
    }).then(resp => {
      if(resp.totalCount === 0) return fetchNearCountry()
      setEvents(prevEvents => {
        return {
          ...prevEvents,
          near: resp.edges.map(i => i.node),
        }
      })
    }).catch(err => {
      setErrors(prevErrors => {
        return {
          ...prevErrors,
          near: true,
        }
      })
    }).finally(() => fetchHighlightedInternational())
  }, [events, errors, location])

  const fetchNearCountry = useCallback(() => {
    // Eventos cerca de ti (país)
    const country = getCountryId(location)
    if(!country) {
      return setEvents(prevEvents => {
        return {
          ...prevEvents,
          near: [],
        }
      })
    }
    const filter = {
      omitHighlighted: true,
      onlyUpcoming: true,
      dateChronological: true,
      country: [country],
      onlyCountry: true,
    }
    getEvents({
      num: 20,
      ord: ['date'],
      filter,
    }).then(resp => {
      setEvents(prevEvents => {
        return {
          ...prevEvents,
          near: resp.edges.map(i => i.node),
        }
      })
    }).catch(err => {
      setErrors(prevErrors => {
        return {
          ...prevErrors,
          near: true,
        }
      })
    }).finally(() => fetchHighlightedInternational())
  }, [events, errors, location])

  const fetchHighlightedRegional = useCallback(() => {
    // Eventos destacados (región)
    const region = getRegionId(location)
    if(!region) return fetchHighlightedCountry()
    getEvents({
      num: 20,
      ord: ['date'],
      filter: {
        onlyHighlighted: true,
        onlyUpcoming: true,
        region: [region],
        onlyRegion: true,
      },
    }).then(resp => {
      if(resp.totalCount === 0) return fetchHighlightedCountry()
      setEvents({
        highlighted: resp.edges.map(i => i.node),
      })
    }).catch(err => {
      setEvents({
        highlighted: [],
      })
      setErrors({
        highlighted: true,
      })
    }).finally(() => fetchNearRegional())
  }, [events, errors, location])

  const fetchHighlightedCountry = useCallback(() => {
    // Eventos destacados (país)
    const country = getCountryId(location)
    if(!country) setEvents({ highlighted: []})
    getEvents({
      num: 20,
      ord: ['date'],
      filter: {
        onlyHighlighted: true,
        onlyUpcoming: true,
        country: [country],
        onlyCountry: true,
      },
    }).then(resp => {
      setEvents({
        highlighted: resp.edges.map(i => i.node),
      })
    }).catch(err => {
      setEvents({

        highlighted: [],
      })
      setErrors({
        highlighted: true,
      })
    }).finally(() => fetchNearRegional())
  }, [events, errors, location])

  const fetchHighlightedInternational = useCallback(() => {
    getEvents({
      num: 20,
      ord: ['date'],
      filter: {
        onlyHighlightedInternational: true,
        onlyUpcoming: true,
      },
    }).then(resp => {
      setEvents((prevEvents) => ({
        ...prevEvents, 
        internationalHighlighted: resp.edges.map(i => i.node), 
      }))
    }).catch(err => {
      setEvents((prevEvents) => ({
        ...prevEvents, 
        internationalHighlighted: [], 
      }))
      setErrors((prevErrors) => ({
        ...prevErrors,
        internationalHighlighted: true,
      }))
    }).finally(()=> fetchInternational())
  }, [events, errors, location])

  useEffect(() => {
    setEvents({})
    setErrors({})
    if(location) {
      fetchHighlightedRegional()
    } else {
      fetchHighlightedInternational()
    }
  }, [location])


  return <Root>
    {
      !errors.highlighted && (events.highlighted?.length > 0 || !events.highlighted) && location && 
        <EventsSlider
          loading={loading || !events.highlighted}
          label={glossary('HighlightedEvents')}
          data={events.highlighted}
        />
    }
    {
      !errors.near && (events.near?.length > 0 || !events.near) && location &&
        <EventsSlider
          loading={loading || !events.near}
          label={glossary('EventsNearToYou')}
          data={events.near}
        />
    }
    {
      !errors.internationalHighlighted && (events.internationalHighlighted?.length > 0 || !events.internationalHighlighted) &&
        <EventsSlider
          loading={loading || !events.internationalHighlighted}
          label={glossary('HighlightedInternationalEvents')}
          data={events.internationalHighlighted}
        />
    }
    {
      !errors.international && (events.international?.length > 0 || !events.international) &&
        <EventsSlider
          loading={loading || !events.international}
          label={glossary('InternationalEvents')}
          data={events.international}
        />
    }
  </Root>
}

export default EventSliders

import { useState } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { Player, Controls } from '@lottiefiles/react-lottie-player'
import { Parallax } from 'react-parallax'
import ScrollAnimation from 'react-animate-on-scroll'
import dayjs from 'dayjs'

import useGlossary from '@hooks/useGlossary'
import StadiboxWhiteLogo from '@icons/logos/StadiboxWhiteLogo'
import SearchField from '@features/Search/SearchFieldv2'
import 'animate.css/animate.css'

const CustomParallax = styled(props => <Parallax {...props} />)(({ theme }) => ({
  '& .react-parallax-content': {
    width: '100%',
    height: '100%',
  },
}))

const ParallaxContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '40% 20% 40%',
  justifyItems: 'center',
  alignItems: 'flex-end',
  gap: 0,
  width: '100%',
  height: '100%',
  padding: '5vh 10%',
  backgroundColor: `${theme.palette.gray[700]}B3`,
}))

const SearchContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

const SearchFieldContainer = styled(Box)(({ theme }) => ({
  height: theme.spacing(6),
  width: 'min(100%, 1200px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const Label = styled('h1')(({ theme }) => ({
  font: theme.font.rubik.paragraph.large,
  color: theme.palette.primary.contrastText,
  textAlign: 'center',
}))

const ScrollContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  cursor: 'pointer',
  opacity: 0,
  animation: 'fadein 3s ease-in-out 5s 1 forwards',
  '@keyframes fadein': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}))

const MiniLabel = styled(Box)(({ theme }) => ({
  font: theme.font.roboto.paragraph.minimal,
  color: theme.palette.primary.contrastText,
  textAlign: 'center',
  fontWeight: '200',
}))

const Hero = ({onScroll, ...props}) => {

  const { glossary } = useGlossary()
  const theme = useTheme()
  const fromTop = useMediaQuery(theme.breakpoints.up(1400))

  const [scrolled, setScrolled] = useState(false)

  const tops = [40, 30, 30, 70, 80, 30, 0]
  const imgNumber = (dayjs().format('d') % 7) + 1

  return <CustomParallax
    bgImage={`/images/HeroCovers/cover-${imgNumber}.jpg`}
    bgImageStyle={fromTop ? { top: `-${tops[imgNumber - 1]}%` } : { bottom: '-10%' }}
    style={{ height:'100vh', width:'100%' }}
    strength={500}
  >
    <ParallaxContainer>
      <ScrollAnimation
        animateOut='animate__fadeOutDown'
        animateIn={scrolled ? 'animate__fadeInUp':''}
        offset={250}
        duration={2}
        initiallyVisible={!scrolled}
        afterAnimatedOut={() => setScrolled(true)}
      >
        <StadiboxWhiteLogo style={{ width: '25vw', height: 'calc( ( 25vw * 59 ) / 392 )' }}/>
      </ScrollAnimation>
      <ScrollAnimation
        animateOut='animate__fadeOutDown'
        animateIn={scrolled ? 'animate__fadeInUp':''}
        offset={200}
        duration={2}
        initiallyVisible={!scrolled}
        afterAnimatedOut={() => setScrolled(true)}
      >
        <SearchContainer>
          <Label>
            { glossary('Slogan') }
          </Label>
          <SearchFieldContainer>
            <SearchField/>
          </SearchFieldContainer>
        </SearchContainer>
      </ScrollAnimation>
      <ScrollAnimation
        animateOut='animate__fadeOutDown'
        animateIn={scrolled ? 'animate__fadeInUp':''}
        offset={50}
        duration={2}
        initiallyVisible={!scrolled}
        afterAnimatedOut={() => setScrolled(true)}
      >
        <ScrollContainer onClick={onScroll}>
          <Player
            autoplay
            loop
            src='/animations/scroll-down.json'
            style={{
              height: '7vh',
              '& svg': {
                transform: 'scale(1.2)',
              },
            }}
          >
            <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
          </Player>
          <MiniLabel>
            { glossary('SeeEvents') }
          </MiniLabel>
        </ScrollContainer>
      </ScrollAnimation>
    </ParallaxContainer>
  </CustomParallax>
}

export default Hero
